import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        boxShadow: theme.shadows[25],
        borderBottom: "1px solid #CCCCCC",
    },
    content: {
        display: "flex",
        width: "100%",
    },
    deleteIcon: {
        color: theme.palette.error.main,
    },
    endAdornment: {
        '& p': {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            fontSize: "16px",
            textAlign: "center",
            height: "2.6rem",
            width: "2.3rem",
            borderTopRightRadius: "3px",
            borderBottomRightRadius: "3px",
            position: "relative",
            left: "1rem",
            padding: "8px 0px"
        }
    },
    editorWrapper: {
        border: "1px solid #e5e5e5",
        borderRadius: "5px"
    },
    editorTextArea: {
        padding: theme.spacing(0, 1)
    },
    toolbar: {
        "& .rdw-dropdown-optionwrapper": {
            maxHeight: "135px"
        }
    },
    collapse: {
        padding: theme.spacing(1, 0, 0, 1),
        marginTop: theme.spacing(2),
        borderTop: "1px solid #EEEEEE",
        backgroundColor: "#FAFAFA"
    },
    colName: {
        fontWeight: "bold",
        color: theme.palette.primary.dark
    },
    messageBody: {
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: "4px",
        padding: "1rem",
        textAlign: "start"
    },
    textEditorButton: {
        textTransform: "lowercase",
        border: "1px solid black"
    }
}));