import React, {useState} from "react";
import CustomDialog from "../../../App/components/CustomDialog";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import {Button} from "@material-ui/core";
import {Spinner} from "reactstrap";
import {useDispatch} from "react-redux";
import {acceptUser} from "../../redux/actions/users";

function AcceptUserInYourSystem({
    open,
    setOpen,
    close,
    user
}) {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const dispatch = useDispatch();

    const acceptUserInSystem = () => {
        setSaving(true);
        throw dispatch(acceptUser(user.id)).then(res => {
            setSaving(false);
            setOpen(false);
        });
    }

    const renderActions = () => {
        return (
            <>
                <Button
                    variant="contained"
                    disableElevation
                    disabled={saving}
                    onClick={close}
                >
                    {t("cancel")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={saving}
                    onClick={acceptUserInSystem}
                >
                    {t("confirm")}
                </Button>
            </>
        )
    }

    return (
        <CustomDialog
            open={open}
            onClose={close}
            title={<Typography variant="h6">{t("user_already_exist")}</Typography>}
            renderContent={() => <Typography>{t("give_access_to_user")}</Typography>}
            renderActions={renderActions}
            saving={saving}
            disableBackdropClick={true}
            maxWidth="xs"
        />
    );
}

export default AcceptUserInYourSystem;