import {
    Box,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Grid,
    IconButton,
    InputAdornment,
    Radio,
    TextField,
    Tooltip,
    Button,
    MenuItem,
    Typography,
    Chip,
    Collapse, Paper
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './style';
import ClearIcon from '@material-ui/icons/Clear';
import { get } from 'lodash-es';
import { Autocomplete } from '@material-ui/lab';
import { adjustDefaultValues } from '../../../../utils/converters';
import UploadFiles from "../../../../App/components/UploadFiles";
import AttachmentCard from "../AttachmentCard";
import {getFileExtensionFromFileName, getFileTypeFromExtension} from "../../../../utils/files";
import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js";
import { Editor } from 'react-draft-wysiwyg';
import PaletteIcon from "../../../../App/assets/icons/paint_palette.svg";
import draftToHtml from "draftjs-to-html";
import CustomDialog from "../../../../App/components/CustomDialog";
import FollowUpEmail from "../../FollowUpEmail";

var timeout = 0;

function RtCreateCard({ rtable, setCreate, instances, setInstances, resetInstances, instance, setInstance }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const [errors, setErrors] = useState(rtable.columns.reduce((obj, col) => ({
        ...obj,
        [col.id]: !col.optional && col.type !== "boolean" && col.type !== "radio" }), {})
    );
    const [disabled, setDisabled] = useState(true);
    const [isUploadFormVisible, setIsUploadFormVisible] = useState(false);
    const rtGlobalList = useSelector(({ rt }) => rt);
    const [selectedAttachment, setSelectedAttachment] = useState({});
    const [validationErrors, setValidationErrors] = useState([]);
    const [openBigText, setOpenBigText] = useState((function () {
        let state = [];
        for (let column of rtable.columns) {
            if (column.type === "bigText") {
                state[column.id] = false;
            }
        }
        return state;
    })());

    const save = () => {
        if (rtable.columns.some(col => col.type === "radio")) {
            instances.forEach(inst => {
                dispatch(rtable.put(inst.id, inst)).then(() => {
                });
            });
        }
        dispatch(rtable.post(adjustDefaultValues(rtable, instance)));
        setCreate(false);
    };

    const loadAttachment = (data, fileName) => {
        const extension = getFileExtensionFromFileName(fileName).toLowerCase();
        setSelectedAttachment({
            file: data,
            fileName,
            fileType: getFileTypeFromExtension(extension)
        });
    }

    const handleDeleteAttachment = (col) => () => {
        setInstance({
            ...instance,
            [col.apiName]: null
        })
    }

    const onRadioChange = (e, col) => {
        setInstance({ ...instance, [col.apiName]: e.target.checked });
        if (e.target.checked) {
            setInstances([...instances].map(i => ({ ...i, [col.apiName]: false })));
        }
    };

    const onCancelClick = () => {
        resetInstances();
        setCreate(false);
    };

    const onTextChange = (col, value) => {
        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(() => {
            setInstance({ ...instance, [col.apiName]: value });
            if (!col.optional) {
                setErrors({ ...errors, [col.id]: value === "" });
            }
            if(col.validated) {
                setErrors({ ...errors, [col.id]: col.optional && (value === "") ? false : !col.validated(value)});
            }
        }, 250);
    };

    const onEditorStateChange = (apiName) => (state) => {
        if(toHTML(state).length > 7){
            setValidationErrors(validationErrors.filter(field => field !== "messageBody"));
        }
        setInstance({
            ...instance,
            [apiName]: toHTML(state)
        });
    };

    const toHTML = (state) => {
        return draftToHtml(convertToRaw(state.getCurrentContent()))
            .replace(new RegExp("<br>", 'g'), "<br/>")
            .replace(new RegExp("\\n", 'g'), "");
    };

    const isFieldValid = (field) => {
        return !validationErrors.includes(field);
    };

    const hasCollapsedColumns = () => {
        return rtable.columns.some(col => col.collapse);
    };

    const onSubmitAttachment = (col) => (data) => {
        setInstance({ ...instance, [col.apiName]: {
                ...selectedAttachment,
                file: data
            }});
        setIsUploadFormVisible(false);
        return new Promise((resolve) => {
            resolve();
        });
    }

    const renderBigTextAreaTitle = (col) => {
        return <h3>{t(col.name)}</h3>
    }

    const closeBigText = (col) => {
        const newState = [...openBigText];
        newState[col.id] = false;
        setOpenBigText(newState);
    };

    const handleOpenBigText = (col) => {
        setOpenBigText(openBigText.map((item, idx) => idx === col.id));
    }

    const renderColumn = (col) => {
        switch (col.type) {
            case "boolean":
                return (
                    <Checkbox
                        defaultChecked={false}
                        color="primary"
                        onChange={e => {
                            setInstance({ ...instance, [col.apiName]: e.target.checked });
                        }}
                    />
                );
            case "radio":
                return (
                    <Radio
                        checked={instance[col.apiName]}
                        color="primary"
                        onChange={e => onRadioChange(e, col)}
                    />
                );
            case "number":
                return (
                    <TextField
                        type="number"
                        placeholder={t(col.name)}
                        variant="outlined"
                        size="small"
                        fullWidth 
                        onChange={e => {
                            setInstance({ ...instance, [col.apiName]: col.reverseTransform ? col.reverseTransform(e.target.value) : e.target.value });
                            !col.optional && setErrors({ ...errors, [col.id]: e.target.value === "" || col.validated ? !col.validated(e.target.value) : false });
                        }}
                        {...(col.inputProps
                            ? {
                                inputProps: col.inputProps
                            }
                            : {})
                        }
                        {
                            ...(
                                col.endAdornment
                                    ? {
                                        InputProps: { endAdornment: <InputAdornment classes={{ root: classes.endAdornment }} position="end">{col.endAdornment}</InputAdornment> }
                                    }
                                    : {}
                            )
                        }
                    />
                );
            case "select":
                return (
                    <TextField
                        select
                        defaultValue=""
                        fullWidth
                        variant="outlined"
                        size="small"
                        onChange={e => {
                            setInstance({ ...instance, [col.apiBase]: rtGlobalList[col.config.storeName].find(x => x.id === e.target.value) || null });
                            !col.optional && setErrors({ ...errors, [col.id]: !e.target.value });
                        }}
                        style={{ width: "100%" }}
                    >
                        {col.optional && <MenuItem value="">{t("none")}</MenuItem>}
                        {rtGlobalList[col.config.storeName].map((item, idx) => <MenuItem key={idx} value={item.id} style={{ width: "100%" }}>{get(item, col.config.apiName)}</MenuItem>)}
                    </TextField>
                );
            case "textList":
                return (    
                    <Autocomplete
                        options={[]}
                        freeSolo
                        multiple
                        renderTags={(value, props) =>
                            value?.map((option, index) => (
                                <Chip 
                                    size="small"
                                    label={option}
                                    style={{backgroundColor: col.validated && col.validated(option) ? "" : "#ff000054"}}
                                    {...props({ index })} 
                                />
                            ))
                        }
                        renderInput={
                            (params) => 
                                <TextField
                                    error={errors[col.id]}
                                    variant="outlined" 
                                    label={t("add_" + col.name)} {...params} 
                                />}
                        value={instance[col.apiName] || []}
                        variant="outlined"
                        size="small"
                        placeholder={t(col.name)}
                        fullWidth
                        {...(col.inputProps
                            ? {
                                inputProps: col.inputProps
                            }
                            : {})}
                        onChange={(e, val) => {
                            setInstance({ ...instance, [col.apiName]: val });
                            !col.optional && setErrors({ ...errors, [col.id]: e.target.value === "" });
                            if(col.validated){
                                let hasInvalidItem = false;
                                for(const item of val){
                                    if(!col.validated(item)){
                                        hasInvalidItem = true;
                                        break;
                                    }
                                }
                                setErrors({ ...errors, [col.id]: hasInvalidItem});
                            }
                        }}
                    />
                );
            case "staticSelect":
                return (
                    <TextField
                        select
                        defaultValue=""
                        fullWidth
                        variant="outlined"
                        size="small"
                        onChange={e => {
                            setInstance({ ...instance, [col.name]: e.target.value || null });
                            !col.optional && setErrors({ ...errors, [col.id]: !e.target.value });
                        }}
                        style={{ width: "100%" }}
                    >
                        {col.optional && <MenuItem value="">{t("none")}</MenuItem>}
                        {col.list.map((item, idx) => <MenuItem key={idx} value={item} style={{ width: "100%" }}>{item}</MenuItem>)}
                    </TextField>
                );
            case "attachment":
                return (
                    <Grid container justifyContent="flex-start">
                        <Grid item container className={classes.mailFieldContainer} justifyContent="space-between">
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disableElevation
                                    className={classes.addAttachmentButton}
                                    onClick={() => {setIsUploadFormVisible(!isUploadFormVisible)}}
                                >
                                    {isUploadFormVisible ? t("close") : t("add_attachment") }
                                </Button>
                            </Grid>
                        </Grid>
                        <Collapse in={isUploadFormVisible}>
                            <Grid container className={classes.uploadContainer} component={Paper}>
                                <Grid item xs={12} className={classes.uploadZone}>
                                    {isUploadFormVisible && <UploadFiles
                                        inline={false}
                                        types={["pdf", "jpg", "png", "jpeg"]}
                                        onSubmit={onSubmitAttachment(col)}
                                        onLoad={loadAttachment}
                                        buttonTitle={t("add")}
                                        validated={selectedAttachment.name !== null && selectedAttachment.name !== "" && selectedAttachment.type !== null}
                                    />}
                                </Grid>
                            </Grid>
                        </Collapse>

                        <Grid item container justifyContent="flex-start" spacing={2}>
                            {instance[col.apiName] &&
                                <AttachmentCard
                                    key={1}
                                    attachment={instance[col.apiName]}
                                    extension={getFileExtensionFromFileName(instance[col.apiName].fileName).toLowerCase()}
                                    handleDeleteAttachment={handleDeleteAttachment(col)}
                                    isIssueMailSent={false}
                                    downLoadAttachment={col.downloadAction(instance.id)}
                                />
                            }
                        </Grid>
                    </Grid>
                );
            case "bigText":
                return (
                    <>
                        <Button className={classes.textEditorButton} onClick={() => handleOpenBigText(col)}>{`${t("edit")} ${t(col.name)}`}</Button>
                        <CustomDialog
                            open={openBigText[col.id]}
                            onClose={() => closeBigText(col)}
                            title={renderBigTextAreaTitle(col)}
                            renderContent={() => {
                                return (
                                    <Editor
                                        defaultEditorState={EditorState.createEmpty()}
                                        wrapperClassName={classes.editorWrapper}
                                        editorClassName={classes.editorTextArea}
                                        toolbarClassName={classes.toolbar}
                                        onEditorStateChange={onEditorStateChange(col.apiName)}
                                        wrapperStyle={{borderColor: !isFieldValid("messageBody") ? "red" : ""}}
                                        toolbar={{
                                            options: ['inline', "list", "colorPicker"],
                                            inline: { isDropdown: false, options: ['bold', 'italic', 'underline', 'strikethrough'] },
                                            list: {
                                                options: ['unordered']
                                            },
                                            colorPicker:{
                                                icon: PaletteIcon
                                            }
                                        }}
                                    />
                                )
                            }}
                            renderActions={() => {
                                return (
                                    <Button onClick={() => {
                                        closeBigText(col);
                                    }}>{t("save")}</Button>
                                )
                            }}
                        />
                    </>
                );
            case "email":
                return (
                    <Grid container justifyContent="space-evenly">
                        <FollowUpEmail
                            col={col}
                            instance={instance}
                            setInstance={setInstance}
                            saveEdit={save}
                            edit={true}
                            create={true}
                        />
                    </Grid>
                );
            case "numberArray":
                return (
                    <Grid container justifyContent="space-evenly">
                        {col.arrayConfig.map((item) => {
                            return (
                                <Grid item container flexDirection="row" justifyContent="space-between" alignItems="center" key={item.id}>
                                    {t(item.name) ? t(item.name) : null} :
                                    <Box mr={1} key={item.id}>
                                        <TextField
                                            type="number"
                                            value={
                                                instance[col.apiName]
                                                    ? (col.transform ? col.transform(instance[col.apiName][item.id]) : instance[col.apiName][item.id])
                                                    : null
                                            }
                                            variant="outlined"
                                            size="small"
                                            placeholder={t(item.name)}
                                            fullWidth
                                            error={errors[col.id]}
                                            onChange={e => {
                                                const val = e.target.value;
                                                const newState = instance[col.apiName] ? instance[col.apiName].map((em, idx) => {
                                                    return (
                                                        idx === item.id
                                                            ?  (val === "" ? null : col.reverseTransform ? col.reverseTransform(val) : val)
                                                            :  em
                                                    );
                                                }) : Array(col.arrayConfig.length).fill(null).map((em, idx) => {
                                                    return (
                                                        idx === item.id
                                                            ? (val === "" ? null : col.reverseTransform ? col.reverseTransform(val) : val)
                                                            : em
                                                    );
                                                })
                                                setInstance({
                                                    ...instance,
                                                    [col.apiName]: newState
                                                });
                                                !col.optional && setErrors({
                                                    ...errors,
                                                    [col.id]: !col.arrayConfig.every((item) => {
                                                        return newState[item.id] !== null
                                                    }) || (col.validated ? !col.validated(newState) : false)
                                                });
                                            }}
                                            {...(col.inputProps
                                                ? {
                                                    inputProps: col.inputProps
                                                }
                                                : {})}
                                            {...(col.endAdornment
                                                ? {
                                                    InputProps: {
                                                        endAdornment: <InputAdornment classes={{ root: classes.endAdornment }} position="end">{col.endAdornment}</InputAdornment>,
                                                    }
                                                }
                                                : {})}
                                        />
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                );
            default:
                return (
                    <TextField
                        type="text"
                        placeholder={t(col.name)}
                        defaultValue=""
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={e => onTextChange(col, e.target.value)}
                        error={col.showError && errors[col.id]}
                    />
                );
        }
    };

    useEffect(() => {
        setDisabled(!Object.values(errors).every(e => {
            if (Array.isArray(e)) {
                return e.every(i => i === false);
            }
            return e === false;
        }));
    }, [errors]);

    useEffect(() => {
        return () => {
            setInstance(null);
        };
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        instance &&
        <Card
            className={classes.root}
            elevation={1}
        >
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={10}>
                    <CardContent className={classes.content} style={{ paddingBottom: "16px" }}>
                        <Grid container>
                            {
                                rtable.columns.filter(col => !col.collapse).map(col =>
                                    <Grid container item key={col.id} xs={col.width} alignItems="center" justifyContent="center" style={{width: "100%"}}>
                                        <Grid item xs={col.fullWidth ? 12 : undefined} style={{width: "100%"}}>
                                            <Box mr={1} textAlign="center">
                                                {renderColumn(col)}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )
                            }{
                                hasCollapsedColumns() &&
                                <Grid item xs={12} className={classes.collapse} >
                                    {
                                        rtable.columns.filter(col => col.collapse).map(col =>
                                            <Grid key={col.id} container alignItems="center" spacing={2}>
                                                <Grid item xs={2}>
                                                    <Typography variant="overline" className={classes.colName}>
                                                        {t(col.name)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {renderColumn(col)}
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            }
                        </Grid>
                    </CardContent>
                </Grid>
                <Grid item xs={2}>
                    <CardActions>
                        <Grid container alignItems="center" justifyContent="center">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    onClick={save}
                                    disabled={disabled}
                                >
                                    {t('save')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Tooltip title={t('cancel')} placement="top">
                                    <IconButton onClick={onCancelClick}>
                                        <ClearIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Grid>
            </Grid>
        </Card>
    );
}

export default RtCreateCard;