import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    mailFieldContainer: {
        marginBottom: "1rem"
    },
    root: {
        display: "flex",
        width: "100%",
        boxShadow: theme.shadows[25],
        borderBottom: "1px solid #CCCCCC",
    },
    content: {
        display: "flex",
        width: "100%",
        paddingBottom: "16px"
    },
    addButton: {
        border: "2px solid",
        "&:hover": {
            border: "2px solid"
        },
        textTransform: "none",
    },
    tableHeader: {
        padding: theme.spacing(0, 2, 2, 2),
        marginTop: theme.spacing(2)
    },
    skeleton: {
        borderRadius: "4px"
    },
    mailTextFieldLabel: {
        width: "15%"
    },
    mailTextField: {
        marginLeft: "1rem",
        width: "80%"
    },
    textField: {
        "& .MuiInputBase-input":{
            color: "black"
        }
    },
    dialogPaper: {
        width: "700px",
        minHeight: "250px",
    },
    editorWrapper: {
        border: "1px solid #e5e5e5",
        borderRadius: "5px"
    },
    availableTagsContainer: {
        marginLeft: "1rem",
        width: "80%",
        backgroundColor: "#ccc",
        padding: "20px",
        borderRadius: "5px"
    }
}))