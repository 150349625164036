import React from 'react';
import {BREADCRUMBS, MANAGE_INVOICING_ROLE} from "../../../utils/constants";

const FollowUps = React.lazy(() => import('../../pages/FollowUps'));

export default [
    {
        path: '/followups',
        exact: true,
        name: 'follow_follow_ups',
        component: FollowUps,
        permissions: [],
        roles: [MANAGE_INVOICING_ROLE],
        private: true,
        className: 'breadcrumb-followups',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: false
            },
            show: false
        }
    },
]