import { makeStyles } from "@material-ui/core";


export default makeStyles((theme) => ({
    textField: {
        backgroundColor: "#fbfbfb",
        borderRadius: 4,
        marginBottom: 4
    },
    textInputs: {
        marginBottom: "15px"
    },
    roleSelect: {
        width: "300px",
        borderRadius: "0",
        marginTop: "300px"
    },
    roleOption: {
        width: "300px"
    },
    chooseRole: {
        fontWeight: "500",
        marginBottom: "15px"
    },    
    adminActive: {
        color: "white",
        backgroundColor: "#ef1d1d",
        padding: "5px 30px",
        borderRadius: "10px",
        border: "2px solid red",
        fontWeight: "600",
        opacity: "0.7",
        marginBottom: "8px",
    },
    adminInactive: {
        color: "#ef1d1d",
        backgroundColor: "white",
        padding: "5px 30px",
        borderRadius: "10px",
        border: "2px solid red",
        fontWeight: "600",
        marginBottom: "8px"
    },
    readOnlyActive: {
        color: "white",
        border: "2px solid #0b73e3",
        opacity: "0.7",
        padding: "5px 30px",
        background: "#2d57ce",
        fontWeight: "600",
        borderRadius: "8px",
        marginBottom: "5px"
    },
    readOnlyInactive: {
        color: "#2d57ce",
        border: "2px solid #0b73e3",
        opacity: "0.7",
        padding: "5px 30px",
        background: "white",
        fontWeight: "600",
        borderRadius: "8px",
        marginBottom: "5px"
    },
    managerActive: {
        backgroundColor: "#57ca71",
        padding: "8px 30px",
        color: "white",
        borderRadius: "10px",
        border: "2px solid green",
        fontWeight: "600",
        marginBottom: "5px"
    },
    managerInactive: {
        backgroundColor: "white",
        padding: "8px 30px",
        color: "#57ca71",
        borderRadius: "10px",
        border: "2px solid green",
        fontWeight: "600",
        marginBottom: "5px"
    }
}))
