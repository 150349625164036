import React, { useCallback, useEffect, useState } from "react";
import {
    Card,
    CardContent,
    CardActions,
    Grid,
    Typography,
    IconButton,
    TextField,
    Box,
    Tooltip,
    Checkbox,
    Radio,
    InputAdornment,
    MenuItem,
    Collapse,
    Chip, Button, Paper, CircularProgress
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './style';
import { useDispatch, useSelector } from "react-redux";
import {adjustDefaultValues, getColorFromPercentage} from '../../../../utils/converters';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { get } from "lodash-es";
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import TextEditorAction from "./TextEditorAction";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Autocomplete } from "@material-ui/lab";
import { convertToRaw } from 'draft-js';
import {
    getFileExtensionFromFileName,
    getFileTypeFromExtension,
} from "../../../../utils/files";
import UploadFiles from "../../../../App/components/UploadFiles";
import AttachmentCard from "../AttachmentCard";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import {downloadResponseGeneric} from "../../../../utils/io";
import draftToHtml from 'draftjs-to-html';
import FollowUpEmail from "../../FollowUpEmail";


function RtCard({ item, rtable, actionModal, setActionModal, displayModal, instances, setInstances, resetInstances, newInstance, setNewInstance }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const [edit, setEdit] = useState(false);
    const [instance, setInstance] = useState(item);
    const [errors, setErrors] = useState(rtable.columns.reduce((obj, col) => ({ ...obj, [col.id]: false }), {}));
    const [disabled, setDisabled] = useState(false);
    const openEdit = useCallback(() => setEdit(true), []);
    const [editorState, setEditorState] = useState(null);
    const [customAction, setCustomAction] = useState(null);
    const [collapseOpen, setCollapseOpen] = useState(false);
    const rtGlobalList = useSelector(({ rt }) => rt);
    const [isUploadFormVisible, setIsUploadFormVisible] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState({});
    const [downloadingAttachment, setDownloadingAttachment] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [openBigText, setOpenBigText] = useState((function () {
        let state = [];
        for (let column of rtable.columns) {
            if (column.type === "bigText") {
                state[column.id] = false;
            }
        }
        return state;
    })());


    const cancelEdit = () => {
        setEdit(false);
        resetInstances();
        setInstance(item);
    };

    const saveEdit = () => {
        if (rtable.columns.some(col => col.type === "radio")) {
            const id = instances.findIndex(inst => inst.id === instance.id);
            if (id > -1) instances[id] = instance;
            instances.forEach(inst => {
                dispatch(rtable.put(inst.id, adjustDefaultValues(rtable, inst)));
            });
        } else {
            dispatch(rtable.put(instance.id, instance));
        }
        setEdit(false);
    };

    const handleDelete = () => {
        setActionModal({
            ...actionModal,
            open: true,
            type: "error",
            title: t('suppression'),
            body: <span>
                {t('confirm_delete')} <b>{t(rtable.name)} {t(rtable.columns[0].transform
                    ? rtable.columns[0].transform(get(instance, rtable.columns[0].apiName))
                    : (
                        !Array.isArray(get(instance, rtable.columns[0].apiName))
                            ? get(instance, rtable.columns[0].apiName)
                            : get(instance, rtable.columns[0].apiName).join(',')))}
                {rtable.columns[0].endAdornment ?? null}</b> ?
            </span>,
            showCloseButton: true,
            onChange: displayModal,
            cancel: {
                title: t('cancel'),
                onClick: null
            },
            confirm: {
                title: t('confirm'),
                onClick: () => {
                    dispatch(rtable.delete(instance.id));
                    displayModal(false);
                }
            }
        });
    };

    const onRadioChange = (e, col) => {
        if (e.target.checked && !!newInstance) {
            setNewInstance({ ...newInstance, [col.apiName]: false });
        }
        setInstances([...instances].map(i => ({ ...i, [col.apiName]: i.id === instance.id ? e.target.checked : (e.target.checked ? !(e.target.checked) : i[col.apiName]) })));
    };

    const handleDeleteAttachment = (col) => () => {
        setInstance({
            ...instance,
            [col.apiName]: null
        })
    }

    const onSubmitAttachment = (col) => (data) => {
        setInstance({ ...instance, [col.apiName]: {
            ...selectedAttachment,
            file: data
        }});
        setIsUploadFormVisible(false);
        return new Promise((resolve) => {
            resolve();
        });
    }

    const loadAttachment = (data, fileName) => {
        const extension = getFileExtensionFromFileName(fileName).toLowerCase();
        setSelectedAttachment({
            file: data,
            fileName,
            fileType: getFileTypeFromExtension(extension)
        });
    }

    const onEditorStateChange = (col) => (state) => {
        if(toHTML(state).length > 7){
            setValidationErrors(validationErrors.filter(field => field !== "messageBody"));
        }
        setInstance({
            ...instance,
            [col.apiName]: toHTML(state)
        });
    };

    const toHTML = (state) => {
        return draftToHtml(convertToRaw(state.getCurrentContent()))
            .replace(new RegExp("<br>", 'g'), "<br/>")
            .replace(new RegExp("\\n", 'g'), "");
    };

    const isFieldValid = (field) => {
        return !validationErrors.includes(field);
    };

    const handleOpenBigText = (col) => {
        setOpenBigText(openBigText.map((item, idx) => idx === col.id));
    }

    const closeBigText = (col) => {
        const newState = [...openBigText];
        newState[col.id] = false;
        setOpenBigText(newState);
    };

    const renderColumn = (col) => {
        switch (col.type) {
            case "boolean":
                if ((rtable.editable || col.editable) && edit) {
                    return (
                        <Checkbox
                            checked={instance[col.apiName]}
                            color="primary"
                            onChange={e => {
                                setInstance({ ...instance, [col.apiName]: e.target.checked });
                            }}
                        />
                    );
                }

                return (
                    <Typography style={col.style}> {get(instance, col.apiName) ? "Oui" : "Non"} </Typography>
                );
            case "radio":
                if ((rtable.editable || col.editable) && edit) {
                    return (
                        <Radio
                            checked={instance[col.apiName]}
                            color="primary"
                            onChange={e => onRadioChange(e, col)}
                        />
                    );
                }

                return (
                    get(instance, col.apiName) && <CheckCircleIcon color="primary" fontSize="small" />
                );
            case "number":
                if ((rtable.editable || col.editable) && edit) {
                    return (
                        <Box mr={1}>
                            <TextField
                                type="number"
                                defaultValue={col.transform ? col.transform(instance[col.apiName]) : instance[col.apiName]}
                                variant="outlined"
                                size="small"
                                placeholder={t(col.name)}
                                fullWidth
                                error={errors[col.id]}
                                onChange={e => {
                                    const val = e.target.value;
                                    setInstance({
                                        ...instance,
                                        [col.apiName]: val === "" ? null : col.reverseTransform ? col.reverseTransform(val) : val });
                                        !col.optional && setErrors({ ...errors, [col.id]: val === "" || col.validated ? !col.validated(val) : false });
                                }}
                                {...(col.inputProps
                                    ? {
                                        inputProps: col.inputProps
                                    }
                                    : {})}
                                {...(col.endAdornment
                                    ? {
                                        InputProps: {
                                            endAdornment: <InputAdornment classes={{ root: classes.endAdornment }} position="end">{col.endAdornment}</InputAdornment>,
                                        }
                                    }
                                    : {})}
                            />
                              
                        </Box>
                    );
                }

                return (
                    <Typography style={col.style}> {get(instance, col.apiName) == null ? "N/A" : col.transform ? col.transform(get(instance, col.apiName)) : get(instance, col.apiName)}{col.endAdornment ?? null} </Typography>
                );
            case "select":
                if ((rtable.editable || col.editable) && edit) {
                    return (
                        <Box mr={1}>
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                value={get(instance, `${col.apiBase}.id`) || ""}
                                error={errors[col.id]}
                                size="small"
                                placeholder={t(col.name)}
                                {...(col.inputProps
                                    ? {
                                        inputProps: col.inputProps
                                    }
                                    : {})}
                                onChange={e => {
                                    setInstance({ ...instance, [col.apiBase]: rtGlobalList[col.config.storeName].find(x => x.id === e.target.value) || null });
                                    !col.optional && setErrors({ ...errors, [col.id]: !e.target.value });
                                }}
                                style={{ width: "100%" }}
                            >
                                {col.optional && <MenuItem value="">{t("none")}</MenuItem>}
                                {rtGlobalList[col.config.storeName].map((item, idx) => <MenuItem key={idx} value={item.id} style={{ width: "100%" }}>{get(item, col.config.apiName)}</MenuItem>)}
                            </TextField>
                        </Box>
                    );
                }

                return (
                    <Typography style={col.style}> {get(instance, col.apiName)} </Typography>
                );
            case "textList":
                if((rtable.editable || col.editable) && edit){
                    return (
                        <Box mr={1}>
                            <Autocomplete
                                options={[]}
                                freeSolo
                                multiple
                                renderTags={(value, props) =>
                                    value.map((option, index) => (
                                        <Chip 
                                            size="small"
                                            label={option} 
                                            style={{backgroundColor: col.validated && col.validated(option) ? "" : "#ff000054"}}
                                            {...props({ index })} 
                                        />
                                    ))
                                }
                                renderInput={
                                    (params) => 
                                        <TextField
                                            error={errors[col.id]}
                                            variant="outlined" 
                                            label={t("add_" + col.name)} {...params} 
                                        />}
                                value={instance[col.apiName] || []}
                                variant="outlined"
                                size="small"
                                placeholder={t(col.name)}
                                fullWidth
                                {...(col.inputProps
                                    ? {
                                        inputProps: col.inputProps
                                    }
                                    : {})}
                                onChange={(e, val) => {
                                    setInstance({ ...instance, [col.apiName]: val });
                                    !col.optional && setErrors({ ...errors, [col.id]: e.target.value === "" });
                                    if(col.validated){
                                        let hasInvalidItem = false;
                                        for(const item of val){
                                            if(!col.validated(item)){
                                                hasInvalidItem = true;
                                                break;
                                            }
                                        }
                                        setErrors({ ...errors, [col.id]: hasInvalidItem});
                                    }
                                }}
                            />
                        </Box>
                    );
                }
                return (
                    <>
                        {get(instance, col.apiName) && get(instance, col.apiName).map(val => (
                            <Chip
                                key={val}
                                label={val}
                                style={{ margin: '5px' }}
                            />
                        ))} 
                    </>);
            case "staticSelect":
                if ((rtable.editable || col.editable) && edit) {
                    return (
                        <TextField
                            select
                            fullWidth
                            variant="outlined"
                            value={get(instance, `${col.apiName}`) || ""}
                            // size="small"
                            // onChange={e => {
                            //     console.log({ ...instance, [col.name]: e.target.value || null });
                            //     setInstance({ ...instance, [col.name]: e.target.value || null });
                            //     !col.optional && setErrors({ ...errors, [col.id]: !e.target.value });
                            // }}
                            // style={{ width: "100%" }}
                            error={errors[col.id]}
                            size="small"
                            placeholder={t(col.name)}
                            {...(col.inputProps
                                ? {
                                    inputProps: col.inputProps
                                }
                                : {})}
                            onChange={e => {
                                setInstance({ ...instance, [col.apiName]: e.target.value || null });
                                !col.optional && setErrors({ ...errors, [col.id]: !e.target.value });
                            }}
                            style={{ width: "100%" }}
                        >
                            {col.optional && <MenuItem value="">{t("none")}</MenuItem>}
                            {col.list.map((item, idx) => <MenuItem key={idx} value={item} style={{ width: "100%" }}>{item}</MenuItem>)}
                        </TextField>
                    );
                }

                return (
                    <Typography style={col.style}> {get(instance, col.apiName)} </Typography>
                );
            case "attachment":
                if ((rtable.editable || col.editable) && edit) {
                    return (
                        <Grid container justifyContent="flex-start">
                            <Grid item container className={classes.mailFieldContainer} justifyContent="space-between">
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        className={classes.addAttachmentButton}
                                        onClick={() => {setIsUploadFormVisible(!isUploadFormVisible)}}
                                    >
                                        {isUploadFormVisible ? t("close") : t("add_attachment") }
                                    </Button>
                                </Grid>
                            </Grid>
                            <Collapse in={isUploadFormVisible}>
                                <Grid container className={classes.uploadContainer} component={Paper}>
                                    <Grid item xs={12} className={classes.uploadZone}>
                                        {isUploadFormVisible && <UploadFiles
                                            inline={false}
                                            types={["pdf", "jpg", "png", "jpeg"]}
                                            onSubmit={onSubmitAttachment(col)}
                                            onLoad={loadAttachment}
                                            buttonTitle={t("add")}
                                            validated={selectedAttachment.name !== null && selectedAttachment.name !== "" && selectedAttachment.type !== null}
                                        />}
                                    </Grid>
                                </Grid>
                            </Collapse>

                            <Grid item container justifyContent="flex-start" spacing={2}>
                                {instance[col.apiName] &&
                                     <AttachmentCard
                                        attachment={instance[col.apiName]}
                                        extension={getFileExtensionFromFileName(instance[col.apiName].fileName).toLowerCase()}
                                        handleDeleteAttachment={handleDeleteAttachment(col)}
                                        isIssueMailSent={false}
                                        downLoadAttachment={col.downloadAction(instance.id)}
                                    />
                                }
                            </Grid>
                        </Grid>
                    )
                }

                if (!instance[col.apiName] || instance[col.apiName].fileName === '') {
                    return null;
                }

                return (
                    <AttachmentCard
                        attachment={instance[col.apiName]}
                        extension={getFileExtensionFromFileName(instance[col.apiName].fileName).toLowerCase()}
                        handleDeleteAttachment={handleDeleteAttachment(col)}
                        isIssueMailSent={true}
                        downLoadAttachment={col.downloadAction(instance.id)}
                    />
                );
            case "bigText":
                // this type comes with an Editor. It also stores the text as html within the DB
                if ((rtable.editable || col.editable) && edit) {
                    return (
                        <FollowUpEmail
                            col={col}
                            instance={instance}
                            setInstance={setInstance}
                            saveEdit={saveEdit}
                            edit={true}
                        />
                        // <>
                        //     <Button className={classes.textEditorButton} onClick={() => handleOpenBigText(col)}>
                        //         {`${t("edit")} ${t(col.name)}`}
                        //     </Button>
                        //     <CustomDialog
                        //         open={openBigText[col.id]}
                        //         onClose={() => closeBigText(col)}
                        //         title={renderBigTextAreaTitle(col)}
                        //         renderContent={() => {
                        //             return (
                        //                 <Editor
                        //                     defaultEditorState={
                        //                         function f() {
                        //                             const blocksFromHTML = convertFromHTML(
                        //                                 instance[col.apiName] ? instance[col.apiName] : ''
                        //                             );
                        //                             return EditorState.createWithContent(ContentState.createFromBlockArray(
                        //                                 blocksFromHTML.contentBlocks,
                        //                                 blocksFromHTML.entityMap
                        //                             ));
                        //                         }()
                        //                     }
                        //                     wrapperClassName={classes.editorWrapper}
                        //                     editorClassName={classes.editorTextArea}
                        //                     toolbarClassName={classes.toolbar}
                        //                     onEditorStateChange={onEditorStateChange(col)}
                        //                     wrapperStyle={{borderColor: !isFieldValid("messageBody") ? "red" : ""}}
                        //                     toolbar={{
                        //                         options: ['inline', "list", "colorPicker"],
                        //                         inline: { isDropdown: false, options: ['bold', 'italic', 'underline', 'strikethrough'] },
                        //                         list: {
                        //                             options: ['unordered']
                        //                         },
                        //                         colorPicker:{
                        //                             icon: PaletteIcon
                        //                         }
                        //                     }}
                        //                 />
                        //             )
                        //         }}
                        //         renderActions={() => {
                        //             return (
                        //                 <Button onClick={() => {
                        //                     saveEdit();
                        //                     closeBigText(col)
                        //                 }}>{t("save")}</Button>
                        //             )
                        //         }}
                        //     />
                        // </>
                    );
                }

                return (
                    <>
                        <FollowUpEmail
                            col={col}
                            instance={instance}
                            setInstance={setInstance}
                            saveEdit={saveEdit}
                            edit={false}
                        />
                        {/*<Button className={classes.textEditorButton} onClick={() => handleOpenBigText(col)}>{`${t("see")} ${t(col.name)}`}</Button>*/}
                        {/*<CustomDialog*/}
                        {/*    open={openBigText[col.id]}*/}
                        {/*    onClose={() => closeBigText(col)}*/}
                        {/*    title={renderBigTextAreaTitle(col)}*/}
                        {/*    renderContent={() => {*/}
                        {/*        return (*/}
                        {/*            <Box className={classes.messageBody}>*/}
                        {/*                <Typography variant="body2" dangerouslySetInnerHTML={{__html: instance[col.apiName]}}></Typography>*/}
                        {/*            </Box>*/}
                        {/*        )*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </>
                );
            case "email":
                if ((rtable.editable || col.editable) && edit) {
                    return (
                        <Grid container justifyContent="space-evenly">
                            <FollowUpEmail
                                col={col}
                                instance={instance}
                                setInstance={setInstance}
                                saveEdit={saveEdit}
                                edit={true}
                            />
                        </Grid>
                    );
                }

                return (
                    <Grid container justifyContent="space-evenly">
                        <FollowUpEmail
                            col={col}
                            instance={instance}
                            setInstance={setInstance}
                            edit={false}
                        />
                    </Grid>
                );
            case "numberArray":
                if ((rtable.editable || col.editable) && edit) {
                    return (
                        <Grid container justifyContent="space-evenly">
                            {col.arrayConfig.map((item) => {
                                return (
                                    <Grid item container flexDirection="row" justifyContent="space-between" alignItems="center" key={item.id}>
                                        {t(item.name) ? t(item.name) : null} : <Box mr={1}>
                                        <TextField
                                            type="number"
                                            defaultValue={
                                                instance[col.apiName]
                                                    ? (col.transform ? col.transform(instance[col.apiName][item.id]) : instance[col.apiName][item.id])
                                                    : null
                                            }
                                            variant="outlined"
                                            size="small"
                                            placeholder={t(item.name)}
                                            fullWidth
                                            error={errors[col.id]}
                                            onChange={e => {
                                                const val = e.target.value;
                                                const newState = instance[col.apiName] ? instance[col.apiName].map((em, idx) => {
                                                    return (
                                                        idx === item.id
                                                            ?  (val === "" ? null : col.reverseTransform ? col.reverseTransform(val) : val)
                                                            :  em
                                                    );
                                                }) : Array(col.arrayConfig.length).fill(null).map((em, idx) => {
                                                    return (
                                                        idx === item.id
                                                            ? (val === "" ? null : col.reverseTransform ? col.reverseTransform(val) : val)
                                                            : em
                                                    );
                                                })
                                                setInstance({
                                                    ...instance,
                                                    [col.apiName]: newState
                                                });
                                                !col.optional && setErrors({
                                                    ...errors,
                                                    [col.id]: !col.arrayConfig.every((item) => {
                                                        return newState[item.id] !== null
                                                    }) || (col.validated ? !col.validated(newState) : false)
                                                });
                                            }}
                                            {...(col.inputProps
                                                ? {
                                                    inputProps: col.inputProps
                                                }
                                                : {})}
                                            {...(col.endAdornment
                                                ? {
                                                    InputProps: {
                                                        endAdornment: <InputAdornment classes={{ root: classes.endAdornment }} position="end">{col.endAdornment}</InputAdornment>,
                                                    }
                                                }
                                                : {})}
                                        />
                                    </Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    );
                }

                return (
                    <Grid container justifyContent="space-evenly">
                        {col.arrayConfig.map((item) => {
                            return (
                                <Typography style={col.style}>
                                    {t(item.name) ? t(item.name) : null} : {get(instance, col.apiName)[item.id] == null ? "N/A" : col.transform ? col.transform(get(instance, col.apiName)[item.id]) : get(instance, col.apiName)[item.id]}{col.endAdornment ?? null}
                                </Typography>
                            )
                        })}
                    </Grid>
                );
            default:
                if ((rtable.editable || col.editable) && edit) {
                    return (
                        <Box mr={1}>
                            <TextField
                                type="text"
                                defaultValue={instance[col.apiName]}
                                variant="outlined"
                                size="small"
                                placeholder={t(col.name)}
                                fullWidth
                                error={errors[col.id]}
                                {...(col.inputProps
                                    ? {
                                        inputProps: col.inputProps
                                    }
                                    : {})}
                                onChange={e => {
                                    const val = e.target.value;
                                    setInstance({ ...instance, [col.apiName]: val.trim() || null });
                                    !col.optional && setErrors({ ...errors, [col.id]: val === "" });
                                    col.validated && setErrors({ ...errors, [col.id]: !col.validated(val)});
                                }}
                            />
                        </Box>
                    );
                }

                return (
                    <Typography style={col.style}> {get(instance, col.apiName)} </Typography>
                );
        }
    };

    const closeCustomActionModal = (display) => {
        displayModal(display);
        !display && setCustomAction(null);
    };

    const hasCollapsedColumns = () => {
        return rtable.columns.some(col => col.collapse);
    };

    const toggleCollapse = () => {
        setCollapseOpen(prev => !prev);
    };

    const renderCustomActions = (actions) => {
        return actions.map((action, idx) => {
            switch (action.type) {
                case "textEditor":
                    return (
                        <Grid key={idx} item>
                            <Tooltip title={t(action.name)} placement="top">
                                <IconButton onClick={() => setCustomAction(action.type)}>
                                    <FormatAlignCenterIcon />
                                </IconButton>
                            </Tooltip>
                            {
                                customAction === action.type &&
                                <TextEditorAction
                                    actionModal={actionModal}
                                    setActionModal={setActionModal}
                                    displayModal={closeCustomActionModal}
                                    editorState={editorState}
                                    setEditorState={setEditorState}
                                    instance={instance}
                                    action={action}
                                />
                            }
                        </Grid>
                    );
                default:
                    return null;
            }
        });
    };

    const getAttachmentColumn = () => {
        return rtable.columns.find((col) => col.type === "attachment");
    }

    const onDownloadClick = (attachment) => {
        const download = getAttachmentColumn().downloadAction(instance.id);
        const {apiName} = getAttachmentColumn();

        setDownloadingAttachment(true);
        dispatch(download()).then(data => {
            if (!data.error) {
                downloadResponseGeneric(data.action.response, instance[apiName].fileName, instance[apiName].fileType);
            }
            setDownloadingAttachment(false);
        });
    };

    const isDocumentPresent = () => {
        const {apiName} = getAttachmentColumn();
        return instance[apiName];
    }

    const renderBigTextAreaTitle = (col) => {
        return <h3>{t(col.name)}</h3>
    }

    useEffect(() => {
        setInstance(item);
    }, [item]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setDisabled(!Object.values(errors).every(e => e === false));
    }, [errors]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (edit) setCollapseOpen(true);
    }, [edit]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Card
                style={
                    rtable.hasColor
                        ? {
                            borderLeft: `5px solid ${getColorFromPercentage(
                                instance.orderPercent
                            )}`,
                        }
                        : {}
                }
                className={classes.root}
                elevation={1}
            >
                <Grid container alignItems="center" justifyContent="center">
                    <Grid
                        item
                        xs={
                            rtable.editable ||
                  rtable.columns.filter((col) => col.editable).length > 0
                                ? 10
                                : 12
                        }
                    >
                        <CardContent
                            className={classes.content}
                            style={{ paddingBottom: "16px" }}
                        >
                            {hasCollapsedColumns() && (
                                <Box mr={1}>
                                    <IconButton
                                        onClick={toggleCollapse}
                                        size="small"
                                        className={classes.collapseButton}
                                    >
                                        {collapseOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                </Box>
                            )}
                            <Grid container>
                                {rtable.columns
                                    .filter((col) => !col.collapse)
                                    .map((col) => (
                                        <Grid
                                            container
                                            item
                                            xs={col.width}
                                            key={col.id}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item xs={col.fullWidth ? 12 : undefined}>
                                                <Box textAlign="center">{renderColumn(col)}</Box>
                                            </Grid>
                                        </Grid>
                                    ))}
                                {hasCollapsedColumns() && (
                                    <Grid item xs={12}>
                                        <Collapse in={collapseOpen}>
                                            <Box className={classes.collapse}>
                                                {rtable.columns
                                                    .filter((col) => col.collapse)
                                                    .map((col) => (
                                                        <Grid key={col.id} container alignItems="center" spacing={2}>
                                                            <Grid item xs={2}>
                                                                <Typography
                                                                    variant="overline"
                                                                    className={classes.colName}
                                                                >
                                                                    {t(col.name)}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                {renderColumn(col)}
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                            </Box>
                                        </Collapse>
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </Grid>
                    {(rtable.editable || rtable.actions) && (
                        <Grid item xs={2}>
                            <CardActions>
                                <Grid container alignItems="center" justifyContent="center">
                                    {getAttachmentColumn() && (
                                        downloadingAttachment
                                            ? <IconButton disabled size="small">
                                                <CircularProgress disableShrink size="1rem" />
                                            </IconButton>
                                            : <Tooltip title={t("download")} placement="top" disableTouchListener={true}>
                                                <span>
                                                    <IconButton
                                                        size="small"
                                                        onClick={onDownloadClick}
                                                        disabled={!isDocumentPresent()}
                                                    >
                                                        <CloudDownloadIcon color={isDocumentPresent() ? 'primary' : 'disabled'} />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                    )}
                                    {rtable.actions && renderCustomActions(rtable.actions)}
                                    {rtable.editable && (
                                        <>
                                            <Grid item>
                                                <Tooltip
                                                    title={edit ? t("save_quick_edit") : t("edit")}
                                                    placement="top"
                                                >
                                                    {edit ? (
                                                        <IconButton
                                                            color="primary"
                                                            onClick={saveEdit}
                                                            disabled={disabled}
                                                        >
                                                            <DoneIcon />
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton onClick={openEdit}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    )}
                                                </Tooltip>
                                            </Grid>
                                            {edit && (
                                                <Grid item>
                                                    <Tooltip title={t("cancel")} placement="top">
                                                        <IconButton onClick={cancelEdit}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <Tooltip title={t("delete")} placement="top">
                                                    <IconButton onClick={handleDelete}>
                                                        <DeleteIcon className={classes.deleteIcon} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </CardActions>
                        </Grid>
                    )}
                    {rtable.columns.filter((col) => col.editable).length > 0 && (
                        <Grid item xs={2}>
                            <CardActions>
                                <Grid container alignItems="center" justifyContent="center">
                                    {rtable.actions && renderCustomActions(rtable.actions)}
                                    <Grid item>
                                        <Tooltip
                                            title={edit ? t("save_quick_edit") : t("edit")}
                                            placement="top"
                                        >
                                            {edit ? (
                                                <IconButton
                                                    color="primary"
                                                    onClick={saveEdit}
                                                    disabled={disabled}
                                                >
                                                    <DoneIcon />
                                                </IconButton>
                                            ) : (
                                                <IconButton onClick={openEdit}>
                                                    <EditIcon />
                                                </IconButton>
                                            )}
                                        </Tooltip>
                                    </Grid>
                                    {edit && (
                                        <Grid item>
                                            <Tooltip title={t("cancel")} placement="top">
                                                <IconButton onClick={cancelEdit}>
                                                    <ClearIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    )}

                                </Grid>
                            </CardActions>
                        </Grid>
                    )}
                </Grid>
            </Card>
        </>
    );
}

export default RtCard;
