import {SET_FOLLOW_UPS_FILTER, SET_FOLLOW_UPS_HISTORY, SET_FOLLOW_UPS_HISTORY_PAGINATION} from '../../constants/index'
import {getInitialPagination} from "../../../../utils/common-state";
import {PAGINATION_MODE} from "../../../../utils/constants";

const initialState = {
    followUpsHistory: {
        config: {
            pagination: JSON.parse(localStorage.getItem("followUpsHistory"))?.pagination ||  getInitialPagination(false, PAGINATION_MODE.MINIFIED)
        },
        filter: {
            clients: [],
            emailFollowUps: []
        },
        list: {
            sort: {
                col: { id: 1 },
                type: "desc"
            },
            page: 0,
            size: getInitialPagination(false, PAGINATION_MODE.MINIFIED).size,
            data: []
        },
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FOLLOW_UPS_HISTORY:
            return setFollowUpsHistory(state, action.payload);
        case SET_FOLLOW_UPS_HISTORY_PAGINATION:
            return setFollowUpsHistoryPagination(state, action.payload);
        case SET_FOLLOW_UPS_FILTER:
            return setFollowUpsFilter(state, action.payload);
        default:
            return state;
    }
}



const setFollowUpsHistory = (state, data) => {
    return {
        ...state,
        followUpsHistory: {
            ...state.followUpsHistory,
            config: {
                ...state.followUpsHistory.config,
                pagination: {
                    ...state.followUpsHistory.config.pagination,
                    page: parseInt(data.page),
                    size: parseInt(data.size),
                    maxLength: parseInt(data.maxLength)
                }
            },
            list: {
                ...state.followUpsHistory.list,
                page: parseInt(data.page),
                size: parseInt(data.size),
                data: data.followUpsHistory
            }
        }
    };
};

const setFollowUpsHistoryPagination = (state, pagination) => {
    const localStorageData = JSON.parse(localStorage.getItem("followUpsHistory")) || {};
    localStorage.setItem("followUpsHistory", JSON.stringify({
        ...localStorageData,
        pagination
    }));

    return {
        ...state,
        followUpsHistory: {
            ...state.followUpsHistory,
            config: {
                ...state.followUpsHistory.config,
                pagination: pagination
            }
        }
    }
}

const setFollowUpsFilter = (state, filter) => {
    const localStorageData = JSON.parse(localStorage.getItem("followUpsHistory")) || {};
    localStorage.setItem("followUpsHistory", JSON.stringify({
        ...localStorageData,
        filter
    }));

    return {
        ...state,
        followUpsHistory: {
            ...state.followUpsHistory,
            filter: filter
        }
    };
}