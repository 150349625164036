import React, {useCallback, useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import {
    Button,
    CardActions,
    CardContent,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Tooltip
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import useStyles from "./style";
import ListColsHeader from "../../../../../App/components/ListColsHeader";
import NoData from "../../../../../App/components/NoData";
import ClearIcon from "@material-ui/icons/Clear";
import {Skeleton} from "@material-ui/lab";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {useDispatch} from "react-redux";
import {
    getAllPaymentReminders,
    postRtPaymentReminder,
    updatePaymentReminder
} from "../../../../redux/actions/rt/paymentReminder";
import Typography from "@material-ui/core/Typography";
import FollowUpEmail from "../../../FollowUpEmail";
import CustomDialog from "../../../../../App/components/CustomDialog";
import {enMonthsLower, LANGUAGES} from "../../../../../utils/constants";
import {ContentState, convertFromHTML, convertToRaw, EditorState, Modifier} from "draft-js";
import draftToHtml from "draftjs-to-html";
import MessageBodyEditor from "./MessageBodyEditor";
import {postInvoice} from "../../../../redux/actions/invoicing";


function RtFollowUpEmail({
                          rTable,
                          displayModal,
                          actionModal,
                          setActionModal,
                          instances,
                          loading,
                      }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const [followUpEmailInstances, setFollowUpEmailInstances] = useState([]);
    const [editFollowUpEmail, setEditFollowUpEmail] = useState(false);
    const [selectedFollowUpEmail, setSelectedFollowUpEmail] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [validationErrors, setValidationErrors] = useState([]);
    const [emailLanguage, setEmailLanguage] = useState(LANGUAGES[0].key);
    const [createEmailFollowUp, setCreateEmailFollowUp] = useState(false);
    const [saving, setSaving] = useState(false);

    const renderColHeaders = () => {
        return (
            <ListColsHeader
                type="columns"
                className={classes.tableHeader}
                columns={rTable.columns}
                disableSort={true}
            />
        );
    }

    const cancelEditCurrency = ()=>{
        setEditFollowUpEmail(false);
        setSelectedFollowUpEmail({});
        setFollowUpEmailInstances(instances)
    }

    const openEditCurrency = useCallback((item) => {
        setEditFollowUpEmail(true);
        setSelectedFollowUpEmail({...item});
    }, []);

    const handleDeleteFollowUpEmail = (item) => {
        setActionModal({
            ...actionModal,
            open: true,
            type: "error",
            title: t("suppression"),
            body: (
                <span>
                    {t("confirm_delete_currency")} <b>{item.code}</b> ?
                </span>
            ),
            showCloseButton: true,
            onChange: displayModal,
            cancel: {
                title: t("cancel"),
                onClick: null,
            },
            confirm: {
                title: t("confirm"),
                onClick: () => {
                    dispatch(rTable.delete(item.id));
                    displayModal(false);
                },
            },
        });
    };

    const saveEditFollowUpEmail = () => {
        dispatch(updatePaymentReminder(selectedFollowUpEmail.id, selectedFollowUpEmail)).then((res) => {
            if (res.status === 200) {
                setEditFollowUpEmail(false);
                setSelectedFollowUpEmail({});
            }
        })
    }

    const renderEditor = (item, col) => () => {
        return (
            <Grid container style={{flexDirection: "column"}}>
                <Grid item container className={classes.mailFieldContainer}  justifyContent="flex-start" alignItems="center">
                    <Grid className={classes.mailTextFieldLabel}>
                        <Typography>{t("label")}</Typography>
                    </Grid>
                    <Grid className={classes.mailTextField}>
                        <TextField
                            className={classes.textField}
                            value={item.label}
                            fullWidth
                            variant="outlined"
                            size="small"
                            error={!isFieldValid("object")}
                            onChange={handleLabelChange}
                        />
                    </Grid>
                </Grid>
                <Grid item container className={classes.mailFieldContainer}  justifyContent="flex-start" alignItems="center">
                    <Grid className={classes.mailTextFieldLabel}>
                        <Typography>{t("period")}</Typography>
                    </Grid>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center" className={classes.mailTextField}>
                        <Grid item xs={6} >
                            <TextField
                                className={classes.textField}
                                type="number"
                                value={item.period && item.period[0]}
                                fullWidth
                                variant="outlined"
                                size="small"
                                label={t("start")}
                                error={!isFieldValid("object")}
                                onChange={handleStartChange}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                className={classes.textField}
                                type="number"
                                label={t("end")}
                                value={item.period && item.period[1]}
                                fullWidth
                                variant="outlined"
                                size="small"
                                error={!isFieldValid("object")}
                                onChange={handleEndChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container className={classes.mailFieldContainer}  justifyContent="flex-start" alignItems="center">
                    <Grid className={classes.mailTextFieldLabel}>
                        <Typography>{t("object")}</Typography>
                    </Grid>
                    <Grid className={classes.mailTextField}>
                        <TextField
                            className={classes.textField}
                            value={
                                getSpecificLanguageFollowUpEmail(item, rTable.columns[3])
                                    ? getSpecificLanguageFollowUpEmail(item, rTable.columns[3]).object
                                    : ""}
                            fullWidth
                            variant="outlined"
                            size="small"
                            error={!isFieldValid("object")}
                            onChange={(e) => handleObjectOnChange(e, rTable.columns[3])}
                        />
                    </Grid>
                </Grid>
                <MessageBodyEditor
                    instance={selectedFollowUpEmail}
                    setInstance={setSelectedFollowUpEmail}
                    col={rTable.columns[3]}
                    emailLanguage={emailLanguage}
                />
            </Grid>
        )
    }

    const getSpecificLanguageFollowUpEmailFieldName = () => {
        switch (emailLanguage) {
            case "fr" : return "emailFr";
            case "us" : return "emailEn";
            case "de" : return "emailDe";
            default:    return null;
        }
    }

    const getSpecificLanguageFollowUpEmail = (item, col) => {
        switch (emailLanguage) {
            case "fr" : return item[col.apiName] && item[col.apiName].emailFr;
            case "us" : return item[col.apiName] && item[col.apiName].emailEn;
            case "de" : return item[col.apiName] && item[col.apiName].emailDe;
            default:    return null;
        }
    }

    const handleObjectOnChange = (e) => {
        if(e.target.value.length > 0){
            setValidationErrors(validationErrors.filter(field => field !== "object"));
        }

        setSelectedFollowUpEmail({
            ...selectedFollowUpEmail,
            [rTable.columns[3].apiName]: {
                ...selectedFollowUpEmail[rTable.columns[3].apiName],
                [getSpecificLanguageFollowUpEmailFieldName()]: {
                    ...selectedFollowUpEmail[rTable.columns[3].apiName][getSpecificLanguageFollowUpEmailFieldName()],
                    object: e.target.value
                }
            }
        });
    };

    const isFieldValid = (field) => {
        return !validationErrors.includes(field);
    }

    const addTagToEditorState = (tag) => (e) => {
        setSelectedFollowUpEmail({
            ...selectedFollowUpEmail,
            [rTable.columns[3].apiName]: {
                ...selectedFollowUpEmail[rTable.columns[3].apiName],
                [getSpecificLanguageFollowUpEmailFieldName()]: {
                    ...selectedFollowUpEmail[rTable.columns[3].apiName][getSpecificLanguageFollowUpEmailFieldName()],
                    textBody: selectedFollowUpEmail[rTable.columns[3].apiName][getSpecificLanguageFollowUpEmailFieldName()].textBody.concat(tag)
                }
            }
        });
    }


    const toHTML = (state) => {
        return draftToHtml(convertToRaw(state.getCurrentContent()))
            .replace(new RegExp("<br>", 'g'), "<br/>")
            .replace(new RegExp("\\n", 'g'), "");
    };

    const getEditorState = () => {
        if (!selectedFollowUpEmail[rTable.columns[3].apiName]) {
            const blocksFromHTML = convertFromHTML('');
            return EditorState.createWithContent(ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            ));
        }

        const blocksFromHTML = convertFromHTML(
            getSpecificLanguageFollowUpEmail(selectedFollowUpEmail, rTable.columns[3])
                ? getSpecificLanguageFollowUpEmail(selectedFollowUpEmail, rTable.columns[3]).textBody
                : ''
        );
        return EditorState.createWithContent(ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        ));
    }

    // useEffect(() => {
    //     dispatch(getAllPaymentReminders()).then((res) => {
    //         if (res.status === 200) {
    //             setAvailableFollowUpEmail(res.data);
    //         }
    //     });
    // }, []);

    const handleLabelChange = (e) => {
        setSelectedFollowUpEmail({
            ...selectedFollowUpEmail,
            label: e.target.value
        });
    }

    const handleStartChange = (e) => {
        setSelectedFollowUpEmail({
            ...selectedFollowUpEmail,
            period: selectedFollowUpEmail.period.map((num, id) => id === 0 ? e.target.value : num),
        });
    }

    const handleEndChange = (e) => {
        setSelectedFollowUpEmail({
            ...selectedFollowUpEmail,
            period: selectedFollowUpEmail.period.map((num, id) => id === 1 ? e.target.value : num),
        });
    }

    const renderActions = () => {
        return (
            <Button onClick={() => {
                if (editFollowUpEmail) {
                    saveEditFollowUpEmail();
                    setEditFollowUpEmail(false);
                }
                if (createEmailFollowUp) {
                    createFollowUpEmail()
                    setCreateEmailFollowUp(false);
                }
            }}>{t("save")}</Button>
        );
    }

    const renderEditorTitle = () => {
        return (
            <Typography variant="h3" style={{padding: "20px", fontSize: "1.2em"}}>
                <Grid container justifyContent="space-between" >
                    {/*{t(col.name)}*/}
                    <Select
                        value={emailLanguage}
                        onChange={(e)=>setEmailLanguage(e.target.value)}
                        label={t("groups")}
                    >
                        {LANGUAGES.map(({ flag, key, text, isSupported }, idx) => {
                            return (
                                <MenuItem key={key} value={key}>
                                    {text}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </Grid>
            </Typography>
        )
    }

    const openCreateCurrencyForm = () => {
        setSelectedFollowUpEmail({
            label: '',
            period: [null, null],
            followUpEmail: {
                emailFr: {
                    object: '',
                    textBody: ''
                },
                emailEn: {
                    object: '',
                    textBody: ''},
                emailDe: {
                    object: '',
                    textBody: ''
                }
            }
        });
        // setSelectedFollowUpEmail({
        //     period: [null, null]
        // });
        setCreateEmailFollowUp(true);
    }

    const closeEditFollowUpEmail = () => {
        setEditFollowUpEmail(false);
        setSelectedFollowUpEmail({});
    }

    const closeCreateEmailFollowUp = () => {
        setCreateEmailFollowUp(false);
        setSelectedFollowUpEmail({});
    }

    const createFollowUpEmail = () => {
        setSaving(true);
        dispatch(postRtPaymentReminder(selectedFollowUpEmail)).then(res => {
            setSaving(false);
        });
    }

    useEffect(() => {
        setFollowUpEmailInstances(instances);
    }, [instances]);

    return (
        <>
            <Box role="tabpanel" mt={4}>
                <Grid container alignItems="flex-start" >
                    <Grid
                        container
                        item
                        xs={12}
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.addButton}
                            startIcon={<AddIcon />}
                            onClick={openCreateCurrencyForm}
                        >
                            {t("add")}
                        </Button>
                    </Grid>
                    <Grid item xs={10}>
                        {renderColHeaders()}
                    </Grid>
                </Grid>
                <NoData
                    condition={instances.length === 0}
                    loading={loading}
                />
                {loading
                    ? [...Array(3)].map((_, index) => (
                        <Box key={index}>
                            <Skeleton
                                height={62.5}
                                variant="rect"
                                animation="wave"
                                calssName={classes.skeleton}
                            />
                        </Box>
                    ))
                    : followUpEmailInstances &&
                        followUpEmailInstances.map((item, index) => (
                            <Grid key={index} className={classes.root} elevation={1}>
                                <Grid item xs={10}>
                                    <CardContent
                                        className={classes.content}
                                        style={{ paddingBottom: "16px" }}
                                    >
                                        <Grid container>
                                            <Grid
                                                container
                                                item
                                                xs={3}
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item>
                                                    <Box textAlign="center">
                                                        <Typography>{item.label ? item.label : "N/A"}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                item
                                                xs={2}
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item>
                                                    <Box textAlign="center">
                                                        <Typography>{item.period[0] ? item.period[0] : "N/A"}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                item
                                                xs={2}
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item>
                                                    <Box textAlign="center">
                                                        <Typography>{item.period[1] ? item.period[1] : "N/A"}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                item
                                                xs={5}
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <FollowUpEmail
                                                    col={rTable.columns[3]}
                                                    instance={item}
                                                    setInstance={setSelectedFollowUpEmail}
                                                    saveEdit={saveEditFollowUpEmail}
                                                    edit={editFollowUpEmail}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CustomDialog
                                        open={editFollowUpEmail}
                                        onClose={closeEditFollowUpEmail}
                                        renderTitle={renderEditorTitle}
                                        renderContent={renderEditor(selectedFollowUpEmail, rTable.columns[3])}
                                        renderActions={renderActions}
                                        classes={{
                                            paper: classes.dialogPaper,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <CardActions>
                                        <Grid container alignItems="center" justifyContent="center">
                                            <Grid item>
                                                <Tooltip
                                                    title={
                                                        editFollowUpEmail && selectedFollowUpEmail.id === item.id
                                                            ? t("save_quick_edit")
                                                            : t("edit")
                                                    }
                                                    placement="top"
                                                >
                                                    {editFollowUpEmail && selectedFollowUpEmail.id === item.id ? (
                                                        <span>
                                                            <IconButton
                                                                color="primary"
                                                                onClick={saveEditFollowUpEmail}
                                                                disabled={disabled}
                                                            >
                                                                <DoneIcon />
                                                            </IconButton>
                                                        </span>
                                                    ) : (
                                                        <IconButton
                                                            onClick={() => openEditCurrency(item)}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    )}
                                                </Tooltip>
                                            </Grid>

                                            {editFollowUpEmail && selectedFollowUpEmail.id === item.id && (
                                                <Grid item>
                                                    <Tooltip title={t("cancel")} placement="top">
                                                        <IconButton onClick={cancelEditCurrency}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                {!item.isDefault && (
                                                    <Tooltip title={t("delete")} placement="top">
                                                        <IconButton
                                                            onClick={() => handleDeleteFollowUpEmail(item)}
                                                        >
                                                            <DeleteIcon className={classes.deleteIcon} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Grid>
                            </Grid>
                        ))
                }
            </Box>

            <CustomDialog
                saving={saving}
                open={createEmailFollowUp}
                onClose={closeCreateEmailFollowUp}
                renderTitle={renderEditorTitle}
                renderContent={renderEditor(selectedFollowUpEmail, rTable.columns[3])}
                renderActions={renderActions}
                classes={{
                    paper: classes.dialogPaper,
                }}
            />
        </>
    );
}

export default RtFollowUpEmail;
