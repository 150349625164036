import appRoutes from '../../view/routing';
import invoicingRoutes from '../../view/routing/InvoicingBase/routes';
import dashboardRoutes from '../../view/routing/DashboardBase/routes';
import administrationRoutes from '../../view/routing/AdministrationBase/routes';
import paymentsRoutes from '../../view/routing/PaymentsBase/routes';
import followUps from '../../view/routing/FollowUps/routes';
import usersRoutes from '../../view/routing/UsersBase/routes'
import analyticsRoutes from "../../view/routing/AnalyticsBase/routes";

export const breadcrumbsRoutes = [
    ...invoicingRoutes,
    ...dashboardRoutes,
    ...administrationRoutes,
    ...appRoutes,
    ...paymentsRoutes,
    ...followUps,
    ...analyticsRoutes,
    ...usersRoutes
];

export default [
    ...appRoutes,
];
