import React from 'react';
import { BREADCRUMBS, MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE } from '../../utils/constants';
import Users from '../pages/Users';
import AnalyticsBase from "./AnalyticsBase";

const DashboardBase = React.lazy(() => import('./DashboardBase'));
const InvoicingBase = React.lazy(() => import('./InvoicingBase'));
const AdministrationBase = React.lazy(() => import('./AdministrationBase'));
const PaymentsBase = React.lazy(() => import('./PaymentsBase'));
const FollowUps = React.lazy(() => import('./FollowUps'))

export default [
    {
        path: '/dashboard',
        exact: false,
        name: 'dashboard',
        component: DashboardBase,
        permissions: [],
        roles: [MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE],
        private: true,
        className: 'breadcrumb-dashboard',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: false
        }
    },
    {
        path: '/invoicing',
        exact: false,
        name: 'invoicing',
        component: InvoicingBase,
        permissions: [],
        roles: [MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE],
        private: true,
        className: 'breadcrumb-invoicing',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: false
        }
    },
    {
        path: '/payments',
        exact: false,
        name: 'payments',
        component: PaymentsBase,
        permissions: [],
        roles: [MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE],
        private: true,
        className: 'breadcrumb-payments',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: false
        }
    },
    {
        path: '/followups',
        exact: true,
        name: 'follow_follow_ups',
        component: FollowUps,
        permissions: [],
        roles: [MANAGE_INVOICING_ROLE],
        private: true,
        className: 'breadcrumb-followups',
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: true
        }
    },
    {
        path: '/analytics',
        exact: false,
        name: 'analytics',
        component: AnalyticsBase,
        permissions: [],
        private: true,
        className:'breadcrumb-settings',
        roles: [MANAGE_INVOICING_ROLE],
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: false
        }
    },
    { 
        path: '/administration',
        exact: false, 
        name: 'administration', 
        component: AdministrationBase, 
        permissions: [],
        private: true, 
        className:'breadcrumb-settings',
        roles: [MANAGE_INVOICING_ROLE],
        breadcrumbs: {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: false
        }
    },
    {
        path: '/users',
        exact: false,
        name: 'users',
        component: Users,
        permissions: [],
        private: true,
        className: '',
        roles: [MANAGE_INVOICING_ROLE],
        breadcrumbs : {
            name: {
                type: BREADCRUMBS.NAME.default,
                display: true
            },
            show: false
        }
    }
];