import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    textEditorButton: {
        textTransform: "none",
        border: "1px solid black"
    },
    mailFieldContainer:{
        marginBottom: "1rem"
    },
    mailTextFieldLabel: {
        width: "15%"
    },
    mailTextField: {
        marginLeft: "1rem",
        width: "80%"
    },
    textField: {
        "& .MuiInputBase-input":{
            color: "black"
        }
    },
    dialogPaper: {
        width: "700px",
        minHeight: "250px",
    },
    editorWrapper: {
        border: "1px solid #e5e5e5",
        borderRadius: "5px"
    },
    availableTagsContainer: {
        marginLeft: "1rem",
        width: "80%",
        backgroundColor: "#ccc",
        padding: "20px",
        borderRadius: "5px"
    },
    availableTagButton: {
        padding: 0,
        textTransform: "lowercase"
    },
    messageBody: {
        border: "1px solid #e5e5e5",
        borderRadius: "5px",
        padding: "10px"
    }
}));