import React, {useEffect, useState} from "react"
import Typography from "@material-ui/core/Typography";
import {Button, Grid, MenuItem, Select, TextField} from "@material-ui/core";
import useStyles from './style';
import {useTranslation} from "react-i18next";
import CustomDialog from "../../../App/components/CustomDialog";
import { ContentState, EditorState, convertFromHTML, convertToRaw, Modifier } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import MailIcon from '@material-ui/icons/Mail';
import Box from "@material-ui/core/Box";
import {LANGUAGES} from "../../../utils/constants";


function FollowUpEmail({ col, instance, setInstance, saveEdit, create = false }) {
    const [validationErrors, setValidationErrors] = useState([]);
    const [openBigText, setOpenBigText] = useState(false);
    const classes = useStyles();
    const [language, setLanguage] = useState(LANGUAGES[0].key)
    const { t } = useTranslation();
    const [editorState, setEditorState] = useState(
        function f() {
            const blocksFromHTML = convertFromHTML(
                (instance[col.apiName] && instance[col.apiName].emailFr && instance[col.apiName].emailFr.textBody)
                    ? instance[col.apiName].emailFr.textBody
                    : ''
            );
            return EditorState.createWithContent(ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            ));
        }()
    );

    const renderBigTextAreaTitle = (col) => {
        return (
            <Typography variant="h3">
                <Grid container justifyContent="space-between" >
                    {/*{t(col.name)}*/}
                    <Select
                        value={language}
                        onChange={(e)=>setLanguage(e.target.value)}
                        label={t("groups")}
                    >
                        {LANGUAGES.map(({ flag, key, text, isSupported }, idx) => {
                            return (
                                <MenuItem key={key} value={key}>
                                    {text}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </Grid>
            </Typography>)
    }

    const isFieldValid = (field) => {
        return !validationErrors.includes(field);
    }

    const toHTML = (state) => {
        return draftToHtml(convertToRaw(state.getCurrentContent()))
            .replace(new RegExp("<br>", 'g'), "<br/>")
            .replace(new RegExp("\\n", 'g'), "");
    };

    const onEditorStateChange = (col) => (state) => {
        if(toHTML(state).length > 7){
            setValidationErrors(validationErrors.filter(field => field !== "messageBody"));
        }
        setEditorState(state);
        setInstance({
            ...instance,
            [col.apiName]: {
                ...instance[col.apiName],
                [getSpecificLanguageFollowUpEmailFieldName()]: {
                    ...instance[col.apiName][getSpecificLanguageFollowUpEmailFieldName()],
                    textBody: toHTML(state)
                }
            }
        });
    }

    const handleObjectOnChange = (e) => {
        if(e.target.value.length > 0){
            setValidationErrors(validationErrors.filter(field => field !== "object"));
        }

        setInstance({
            ...instance,
            [col.apiName]: {
                ...instance[col.apiName],
                [getSpecificLanguageFollowUpEmailFieldName()]: {
                    ...instance[col.apiName][getSpecificLanguageFollowUpEmailFieldName()],
                    object: e.target.value
                }
            }
        });
    };


    const getSpecificLanguageFollowUpEmail = () => {
        switch (language) {
            case "fr" : return instance[col.apiName].emailFr;
            case "us" : return instance[col.apiName].emailEn;
            case "de" : return instance[col.apiName].emailDe;
            default:    return null;
        }
    }

    const getSpecificLanguageFollowUpEmailFieldName = () => {
        switch (language) {
            case "fr" : return "emailFr";
            case "us" : return "emailEn";
            case "de" : return "emailDe";
            default:    return null;
        }
    }



    const renderActions = () => {
        return (
            <Button onClick={() => {
                if (!create) {
                    saveEdit();
                }
                setOpenBigText(false);
            }}>{t("save")}</Button>
        );
    }

    const renderEmailContent = () => {
        return (
            <Grid container style={{flexDirection: "column"}}>
                 <Grid item container className={classes.mailFieldContainer}  justifyContent="flex-start" alignItems="center">
                     <Grid className={classes.mailTextFieldLabel}>
                         <Typography>{t("object")}</Typography>
                     </Grid>
                     <Grid className={classes.mailTextField}>
                         <TextField
                             className={classes.textField}
                             value={
                                 getSpecificLanguageFollowUpEmail()
                                     ? getSpecificLanguageFollowUpEmail().object
                                     : ""}
                             fullWidth
                             variant="outlined"
                             size="small"
                             disabled
                         />
                     </Grid>
                 </Grid>
                 <Grid item container className={classes.mailFieldContainer} justifyContent="flex-start" alignItems="flex-start">
                     <Grid className={classes.mailTextFieldLabel}>
                         <Typography>{t("message")}</Typography>
                     </Grid>
                     <Grid className={classes.mailTextField}>
                         <Box className={classes.messageBody}>
                             <Typography variant="body2" dangerouslySetInnerHTML={{__html: (
                                     getSpecificLanguageFollowUpEmail()
                                         ? getSpecificLanguageFollowUpEmail().textBody
                                         : ""
                                 )}}
                             />
                         </Box>
                     </Grid>
                 </Grid>
            </Grid>
        )
     }

    useEffect(() => {
        const blocksFromHTML = convertFromHTML(
            (instance[col.apiName] && getSpecificLanguageFollowUpEmail())
                ? getSpecificLanguageFollowUpEmail().textBody
                : ''
        );
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        )));
    }, [language]);

    return (
        <>
            <Button className={classes.textEditorButton} onClick={() => setOpenBigText(true)}>
            <span style={{width: "70px"}} className="d-flex justify-content-between">
                <MailIcon /> {t(col.name)}
            </span>

            </Button>
            <CustomDialog
                open={openBigText}
                onClose={() => setOpenBigText(false)}
                title={renderBigTextAreaTitle(col)}
                renderContent={renderEmailContent}
                classes={{
                    paper: classes.dialogPaper,
                }}
            />
        </>
    );
}

export default FollowUpEmail;
