import React, {useEffect, useState} from "react";
import { Editor } from 'react-draft-wysiwyg';
import {ContentState, convertFromHTML, convertToRaw, EditorState, Modifier} from "draft-js";
import PaletteIcon from "../../../../../../App/assets/icons/paint_palette.svg";
import useStyles from './style';
import draftToHtml from "draftjs-to-html";
import Typography from "@material-ui/core/Typography";
import {Button, Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {setIn} from "immutable";


function MessageBodyEditor({ instance, setInstance, col, emailLanguage, addTag }) {
    const classes = useStyles();
    const [validationErrors, setValidationErrors] = useState([]);
    const { t } = useTranslation();
    const [editorState, setEditorState] = useState(
        function f() {
            const blocksFromHTML = convertFromHTML(
                (instance[col.apiName] && instance[col.apiName].emailFr && instance[col.apiName].emailFr.textBody)
                    ? instance[col.apiName].emailFr.textBody
                    : ''
            );
            return EditorState.createWithContent(ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            ));
        }()
    );

    const onEditorStateChange = (state) => {
        if(toHTML(state).length > 7){
            setValidationErrors(validationErrors.filter(field => field !== "messageBody"));
        }
        setEditorState(state);
        setInstance({
            ...instance,
            [col.apiName]: {
                ...instance[col.apiName],
                [getSpecificLanguageFollowUpEmailFieldName()]: {
                    ...instance[col.apiName][getSpecificLanguageFollowUpEmailFieldName()],
                    textBody: toHTML(state)
                }
            }
        });
    }

    const isFieldValid = (field) => {
        return !validationErrors.includes(field);
    }

    const toHTML = (state) => {
        return draftToHtml(convertToRaw(state.getCurrentContent()))
            .replace(new RegExp("<br>", 'g'), "<br/>")
            .replace(new RegExp("\\n", 'g'), "");
    };

    const getSpecificLanguageFollowUpEmailFieldName = () => {
        switch (emailLanguage) {
            case "fr" : return "emailFr";
            case "us" : return "emailEn";
            case "de" : return "emailDe";
            default:    return null;
        }
    }

    const getSpecificLanguageFollowUpEmail = (item, col) => {
        switch (emailLanguage) {
            case "fr" : return item[col.apiName] && item[col.apiName].emailFr;
            case "us" : return item[col.apiName] && item[col.apiName].emailEn;
            case "de" : return item[col.apiName] && item[col.apiName].emailDe;
            default:    return null;
        }
    }

    const addTagToEditorState = (tag) => (e) => {
        const currentContent = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();

        const newContent = Modifier.insertText(
            currentContent,
            selectionState,
            tag
        );

        const newEditorState = EditorState.push(
            editorState,
            newContent,
            'insert-characters'
        );

        setEditorState(newEditorState);
        setInstance({
            ...instance,
            [col.apiName]: {
                ...instance[col.apiName],
                [getSpecificLanguageFollowUpEmailFieldName()]: {
                    ...instance[col.apiName][getSpecificLanguageFollowUpEmailFieldName()],
                    textBody: toHTML(newEditorState)
                }
            }
        });
    }

    useEffect(() => {
        const blocksFromHTML = convertFromHTML(
            (getSpecificLanguageFollowUpEmail(instance, col) && getSpecificLanguageFollowUpEmail(instance, col).textBody)
                ? getSpecificLanguageFollowUpEmail(instance, col).textBody
                : ''
        );

        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        )));
    }, [emailLanguage]);

    return (
        <>
            <Grid item container className={classes.mailFieldContainer} justifyContent="flex-start" alignItems="flex-start">
                <Grid className={classes.mailTextFieldLabel}>
                    <Typography>{t("message")}</Typography>
                </Grid>
                <Grid className={classes.mailTextField}>
                <Editor
                    editorState={editorState}
                    wrapperClassName={classes.editorWrapper}
                    editorClassName={classes.editorTextArea}
                    toolbarClassName={classes.toolbar}
                    onEditorStateChange={onEditorStateChange}
                    wrapperStyle={{borderColor: !isFieldValid("messageBody") ? "red" : ""}}
                    toolbar={{
                        options: ['inline', "list", "colorPicker"],
                        inline: { isDropdown: false, options: ['bold', 'italic', 'underline', 'strikethrough'] },
                        list: {
                            options: ['unordered']
                        },
                        colorPicker:{
                            icon: PaletteIcon
                        }
                    }}
                />
                </Grid>
            </Grid>
            <Grid item container className={classes.mailFieldContainer} justifyContent="flex-start" alignItems="flex-start">
                <Grid className={classes.mailTextFieldLabel}>
                    <Typography>{t("available_tags")}</Typography>
                </Grid>
                <Grid className={classes.availableTagsContainer}>
                    {col.availableTags.map((item, idx) => {
                        return (
                            <>
                                <Button onClick={addTagToEditorState(item.name)} className={classes.availableTagButton}>
                                    {item.name}
                                </Button>
                                {(idx !== col.availableTags.length - 1) && ","}
                            </>
                        );
                    })}
                </Grid>
            </Grid>
        </>
);
}

export default MessageBodyEditor;