import {FINANCIAL_RT_ENDPOINT} from "../../constants/endpoint";
import {
    DELETE_RT_PAYMENT_REMINDER,
    GET_ALL_RT_PAYMENT_REMINDERS,
    POST_RT_PAYMENT_REMINDER, SET_ALL_RT_PAYMENT_REMINDERS,
    UPDATE_PAYMENT_REMINDER
} from "../../constants";
import {onError, onSuccess} from "../../../../utils/http";
import {errorNotification, successNotification} from "../../../../utils/notification";

export const getAllPaymentReminders = () => {
    return {
        type: GET_ALL_RT_PAYMENT_REMINDERS,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/paymentReminder`
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                store.dispatch(setAllPaymentsReminder(response.data));
                return response;
            })
        }
    }
}

export const setAllPaymentsReminder = (data) => {
    return {
        type: SET_ALL_RT_PAYMENT_REMINDERS,
        payload: data
    };
};

export const postRtPaymentReminder = (paymentReminder) => {
    return {
        type: POST_RT_PAYMENT_REMINDER,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/paymentReminder`,
            method: "post",
            data: paymentReminder
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "create_success");
                store.dispatch(getAllPaymentReminders());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "create_error");
                throw error;
            })
        }
    };
};

export const updatePaymentReminder = (paymentReminderId, paymentReminder) => {
    return {
        type: UPDATE_PAYMENT_REMINDER,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/paymentReminder/${paymentReminderId}`,
            method: 'put',
            data: paymentReminder
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "update_success");
                store.dispatch(getAllPaymentReminders());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "update_error");
                throw error;
            })
        }
    };
};

export const deleteRtPaymentReminder = (id) => {
    return {
        type: DELETE_RT_PAYMENT_REMINDER,
        request: {
            url: `${FINANCIAL_RT_ENDPOINT}/paymentReminder/${id}`,
            method: "delete"
        },
        meta: {
            onSuccess: onSuccess(({ response, store }) => {
                successNotification('', "delete_success");
                store.dispatch(getAllPaymentReminders());
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', error.response.status === 409 ? "ressource_in_use_error" : "delete_error");
                throw error;
            })
        }
    }
}